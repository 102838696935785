::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  background: #000; 
  border-radius: 8px;
}
::-webkit-scrollbar-thumb:hover {
  background: #000; 
}
html {
  height: 100%;
}
body {
  background-color: #000;
  height: 100%;
}
#root {
  height: 100%;
}
.counter-wrapper {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  letter-spacing:-0.5px; 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.counter-wrapper.loading {
  height: 100%;
}
.counter-wrapper .item {
  border: 1px solid transparent;
  height: 225px;
  margin: .2%;
  overflow: hidden;
  padding: 0;
  width: 19.4%;
  display: flex;
  flex-direction: column;
}
.counter-wrapper .item.not-working .flip-card-front,
.counter-wrapper .item.not-working .flip-card-back{
  background: #ff0000;
}
.counter-wrapper .item.working .flip-card-front,
.counter-wrapper .item.working .flip-card-back{
  background: #00e600;
}
.counter-wrapper .item.paused .flip-card-front,
.counter-wrapper .item.paused .flip-card-back{
  background: #ffff00;
}
.counter-wrapper .item.tableview {
  background: #fff;
  width: 96%;
  float: none;
  margin: 2vh 2%;
  height: 30vh;
}
.counter-wrapper .item h2 {
  border-bottom: 2px solid #222;
  color: #000;
  font-size: 30px;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 0;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex-shrink: 0;
}
.counter-wrapper .item .flip-card-front h2 {
  border: 0;
  overflow: visible;
  white-space: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  line-height: 1.3;
  font-weight: bold;
  font-size: 40px;
  box-sizing: border-box;
}
.counter-wrapper .item.tableview h2 {
  font-size: 80px;
  letter-spacing: 2px;
}
.counter-wrapper .item .tasks {
  overflow-x: auto;
  max-height: 173px;
}
.counter-wrapper .item .task {
  border-bottom:1px solid #222;
  font-size:12px;
  padding:5px 10px;
  color:#000;
  line-height: 1.2;
}
.counter-wrapper .item .task a:link,
.counter-wrapper .item .task a:visited {
  color:#000;
  text-decoration: none;
}
.counter-wrapper .item .task a:hover {
  text-decoration: underline;
}
.counter-wrapper .item.tableview .task {
  font-size:15px;
}
.counter-wrapper .item .task strong {
  display: block;
  font-size:12px;
  text-transform:uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.counter-wrapper .item .task > span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.counter-wrapper .item.tableview .task strong {
  font-size:18px;
  letter-spacing: 1.7px;
}
.counter-wrapper .item .task b {
  font-size:13px;
}
.counter-wrapper .item.tableview .task b {
  font-size:16px;
  letter-spacing: 1.7px;
}
.counter-wrapper .item .task.zzz {
  background: url(images/zzzz.png) no-repeat center center;
  background-size: auto 120px;
  text-indent: -9999px;
  height: 153px;
  margin-top: 0;
  border: 0 none;
}
.counter-wrapper .item .task.not-active {
  background-color: #ffff00;
}
.counter-wrapper .item .task.active {
  background-color: #00e600;
}
.flip-card {
  background-color: transparent;
  perspective: 1000px;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
.flip-card-back {
  transform: rotateY(180deg);
}

@media all and (max-width: 1355px) {
  .counter-wrapper .item h2 {
    font-size: 20px;
  }
  .counter-wrapper .item .flip-card-front h2 {
    font-size: 30px;
  }
}

@media all and (max-width: 1099px) {
  .counter-wrapper .item {
    height: 225px;
    margin: .2%;
    width: 24.3%;
  }
}

@media all and (max-width: 799px) {
  .counter-wrapper .item {
    height: 225px;
    margin: .2%;
    width: 32.6%;
  }
}

@media all and (max-width: 599px) {
  .counter-wrapper .item {
    height: 225px;
    margin: .2%;
    width: 49.1%;
  }
}

@media all and (max-width: 399px) {
  .counter-wrapper .item {
    height: 225px;
    margin: .2%;
    width: 99.6%;
  }
}